/* LectorAttendanceHistory.css */
.lector-attendance-history-container {
    padding: 20px;
  }
  
  .course-button {
    margin-bottom: 10px;
  }
  
  /* You can add more styles as needed for the attendance history table */
  