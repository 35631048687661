.login-popup {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 80%;
  max-width: 400px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  color: black;
}

.login-popup.success {
  border: 1px solid black;
  background-color: rgba(0, 128, 0, 0.1); /* slightly transparent green */
}

.login-popup.error {
  border: 1px solid red;
  background-color: rgba(255, 0, 0, 0.1); /* slightly transparent red */
}
