
.agreement-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    background-color: #fafafa;
    max-width: 400px;
    margin: 20px;
}

.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.checkbox-container input[type="checkbox"] {
    display: none;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    margin-right: 10px;
    display: inline-block;
    background-color: #fff;
    position: relative;
}

.checkbox-container input[type="checkbox"]:checked + .custom-checkbox::before {
    content: '✓';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
}

a {
    color: #007BFF;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
