.admin-course-container {
  max-width: 800px;
  margin: 0 auto;
}

.course-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
}

.course-info {
  display: block;
  margin-bottom: 5px;
}

.course-info-edit {
  display: block;
  margin-bottom: 5px;
  border: 1px solid #ccc;
  padding: 5px;
}

.edited {
  border-color: red;
}

button {
  margin-right: 5px;
}

.new-course-form {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.input-field {
  display: block;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
}

.create-button {
  margin-top: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.create-button:hover {
  background-color: #45a049;
}

.edit-button {
  background-color: #f4c20d;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}

.edit-button:hover {
  background-color: #e6b408;
}

button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}

button:hover {
  background-color: #d32f2f;
}

/* adminCourse.css */
.course-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 8px;
}

.course-item td {
  padding: 8px;
  border: 1px solid #ccc;
}

.course-item td button {
  background-color: #f4c20d;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}

.course-item td button:hover {
  background-color: #e6b408;
}

.course-item td a {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
}

.course-item td a:hover {
  background-color: #45a049;
}

/* Table styles */
table {
  width: 100%;
  align-items: center;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

/* Header row styles */
thead th {
  background-color: #f2f2f2;
}

/* Alternating row colors */
tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Button styles */
button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  margin-right: 5px;
}

button:hover {
  background-color: #d32f2f;
}

/* Edit button styles */
.edit-button {
  background-color: #f4c20d;
}

.edit-button:hover {
  background-color: #e6b408;
}




