#main-header {
  height: 80px;  /* Adjust based on your actual header height */
}

#subheader {
  position: static;
  width: 100%;
  z-index: 1000;
}

#subheader.slideDown {
  position: fixed;
  top: 0;
  animation: slideDown 1.5s forwards;
}

@keyframes slideDown {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}

.subheader-placeholder {
  height:  80px; /* replace with actual height */
  display: block;
}
