.admin-user-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .user-item {
    border: 1px solid #ccc;
    padding: 20px;
    margin-bottom: 10px;
  }
  
  .user-info {
    display: block;
    margin-bottom: 5px;
  }
  
  .user-info-edit {
    display: block;
    margin-bottom: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .user-info-edit.edited {
    border-color: #f00;
  }
  
  button {
    margin-right: 10px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 15px;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  a {
    text-decoration: none;
  }

  /* Add styles for the input when editing */
.user-info-edit {
    display: block; /* Make the input a block element */
    width: 100%; /* Set the input width to fill the container */
    padding: 8px; /* Add some padding for better appearance */
    margin-bottom: 10px; /* Add spacing between inputs */
    font-size: 16px; /* Set the font size */
    border: 1px solid #ccc; /* Add a border to the input */
    border-radius: 4px; /* Add some border radius for a rounded look */
  }
  
  /* Add styles for the input when it is edited (optional) */
  .user-info-edit.edited {
    border-color: #007bff; /* Change border color when edited */
    box-shadow: 0 0 5px #007bff; /* Add a box shadow when edited */
  }

  .user-info-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .user-info-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .user-item {
    border: 1px solid #ccc;
  }
  
  .user-item td {
    padding: 8px;
  }
  
  .user-item td:first-child {
    font-weight: bold;
    background-color: #f1f1f1;
    width: 150px;
  }
  
  .user-item td:last-child {
    text-align: right;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  button:hover {
    background-color: #45a049;
  }
  
  button:last-child {
    margin-right: 0;
  }
  
  .link-button {
    text-decoration: none;
  }
  