.user-details-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .user-info span {
    font-size: 16px;
  }
  
  .user-info-edit {
    border: 1px solid #ccc;
    padding: 5px;
    font-size: 16px;
  }
  
  .edited {
    border-color: #ff7f50; /* Coral color */
  }
  
  button {
    padding: 10px;
    background-color: #007bff; /* Blue color */
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3; /* Darker blue color on hover */
  }
  
  .user-links {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .user-links button {
    background-color: #28a745; /* Green color */
  }
  
  .user-links button:hover {
    background-color: #1e7e34; /* Darker green color on hover */
  }
  