/* ChildUserDetails.css */
.child-user-details {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .child-user-details h1 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .child-user-details p {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 10px;
  }
  
  .child-user-details button {
    font-size: 1em;
    color: #fff;
    padding: 10px 20px;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .child-user-details button:hover {
    background-color: #0056b3;
  }
  
  .child-user-details input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 1em;
    margin-bottom: 10px;
    width: 100%;
  }

  .success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  