.cookie-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #f9f9f9;
    color: #333;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: 1000; 
    border-top: 1px solid #e0e0e0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.box {
    flex-grow: 1;
    margin-right: 10px; /* Creates a space between the text and the button */
    font-size: 16px;
    font-family: Arial, sans-serif;
    line-height: 1.5;
    color: #333;
}

.navigation-icon {
    background-color: #4CAF50; /* You can change this color to any you prefer */
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 0 10px; /* Spacing between the buttons */
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
    font-weight: bold;
}

.navigation-icon.reject {
    background-color: #f44336; /* Red color for Reject button */
}

.navigation-icon:hover {
    opacity: 0.9;
}

@media only screen and (max-width: 600px) {
    .cookie-banner {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
    }

    .box {
        margin-right: 0;
        margin-bottom: 10px; 
        max-height: 100px;
        overflow-y: auto; /* Add scroll when content overflows */
    }

    .navigation-icon {
        margin: 0; /* Resetting the margin to 0 */
        width: 100%; /* Making the buttons full width */
    }
}
