.courses {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    background-color: #f4f4f4;
  }
  
  .course {
    background-color: white;
    margin: 1em;
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
    width: 80%;
  }
  
  .course h2 {
    margin: 0;
    color: #333;
  }
  
  .course p {
    margin: 0.5em 0;
  }
  
  .course-button {
    display: block;
    margin-top: 1em;
    padding: 0.5em 1em;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .course-button:hover {
    background-color: #0056b3;
  }
  