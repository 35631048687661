.admin-children-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .children-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .children-table th,
  .children-table td {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .children-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    text-align: left;
  }
  
  .children-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .children-table tr:hover {
    background-color: #ddd;
  }
  
  .details-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .details-button:hover {
    background-color: #45a049;
  }
  