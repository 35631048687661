/* Main2.css */
.container2,
.container22 {
  position: relative;
  overflow: hidden;
  min-height: 60vh;  /* Reduced height */
  background-image: url('https://teachyourkidscode.com/wp-content/uploads/2018/12/Teach-Your-Kids-Code-Front-Page-Image-1.jpg');
  background-size: cover;
  color: #fff;
  text-align: center;
  padding-bottom: 3rem;  /* Added space at the bottom for infoBoxContainer */
}

.content,
.content22 {
  position: absolute;
  top: 50%; /* Changed from 27.5% to 50% for vertical centering */
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;  /* Reduced width */
  padding: 30px;  /* Reduced padding */
  background-color: #ffffff;
  border-radius: 10px;
}

.content h1,
.content22 h1 {
  font-size: 38px;  /* Reduced font size */
  margin-bottom: 20px;  /* Reduced margin */
  color: black;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.content p,
.content22 p {
  font-size: 18px;  /* Reduced font size */
  margin-bottom: 30px;  /* Reduced margin */
  color: #333;
}

.content a,
.content22 a {
  display: inline-block;
  padding: 8px 15px;  /* Reduced padding */
  background-color: #007bff;
  color: black;
  text-decoration: none;
  border-radius: 5px;
}

.content a:hover,
.content22 a:hover {
  background-color: #0056b3;
}

.infoBoxContainer,
.infoBoxContainer2 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;  /* Allow boxes to wrap on smaller screens */
  margin-top: 2rem;  /* Space between container and infobox container */
}

.infoBox,
.infoBox2 {
  flex: 1;
  max-width: 20%;
  padding: 15px;  /* Reduced padding */
  margin: 5px;  /* Reduced margin */
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  color: black;
  transition: transform 0.2s ease-in-out;
  min-width: 150px;  /* Minimum width to ensure content fits */
}

.infoBox:hover,
.infoBox2:hover {
  transform: translateY(-5px);
}

.infoBox h3,
.infoBox2 h3 {
  font-size: 22px;  /* Reduced font size */
  margin-bottom: 8px;  /* Reduced margin */
}

.infoBox p,
.infoBox2 p {
  font-size: 12px;  /* Reduced font size */
  margin-bottom: 15px;  /* Reduced margin */
}

.icon {
  margin-bottom: 10px;
}


/* Add mobile-specific styles here */
@media (max-width: 768px) {
  .container2 {
    background-image: url('https://teachyourkidscode.com/wp-content/uploads/2018/12/Teach-Your-Kids-Code-Front-Page-Image-1.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    height: 50px; /* set the height */
    margin-bottom: 0px;
  }

  .container22 {
    background-image: url('https://teachyourkidscode.com/wp-content/uploads/2018/12/Teach-Your-Kids-Code-Front-Page-Image-1.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    height: 50px; /* set the height */
    margin-bottom: 0px;
  }

  .icon {
    font-size: 50px;
  }

  .FaGraduationCap {
    font-size: 150px;
  }

  .content22 {
    position: relative; /* Use relative instead of absolute */
    top: 10%; /* Move 10% from the top */
    left: 50%;
    transform: translateX(-50%); /* Only translateX needed */
    width: 40%; /* Reduce the width */
    max-width: 500px; /* Reduce the maximum width */
    padding: 10px; /* Reduce padding */
    background-color: #ffffff; /* White background */
    border-radius: 10px;
    margin-top: 40px; /* Add 10px margin to the top */
}

  .content22 h1 {
    font-size: 20px; /* Reduce the font size of h1 */
    max-width: 450px;
  }
  
  .content22 p {
    font-size: 13px; /* Reduce the font size of p */
  }

  .content {
    position: relative; /* Use relative instead of absolute */
    top: 10%; /* Move 10% from the top */
    left: 50%;
    transform: translateX(-50%); /* Only translateX needed */
    width: 40%; /* Reduce the width */
    max-width: 500px; /* Reduce the maximum width */
    padding: 10px; /* Reduce padding */
    background-color: #ffffff; /* White background */
    border-radius: 10px;
    margin-top: 40px; /* Add 10px margin to the top */
}

  .content h1 {
    font-size: 20px; /* Reduce the font size of h1 */
    max-width: 450px;
  }
  
  .content p {
    font-size: 13px; /* Reduce the font size of p */
  }
  

  .infoBoxContainer {
    position: relative; /* Use relative instead of absolute */
    top: 0; /* Reset top to 0 */
    left: 50%;
    transform: translateX(-50%); /* Only translateX needed */
    display: flex;
    flex-direction: row; /* Set items in a row */
    flex-wrap: wrap; /* Allow items to wrap onto new lines */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.infoBox {
    flex: 0 0 auto; /* Reset flex for box */
    width: calc(100% / 3 - 20px); /* Set width for the box, three per row with 20px gap */
    height: 150px; /* Set smaller height for the box */
    padding: 10px; /* Reduce padding for smaller size */
    margin: 10px; /* Add margin to separate the boxes on mobile */
    min-height: 150px;
    border-radius: 10px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
  }


  .infoBox:hover {
    transform: none; /* Remove hover effect on mobile */
  }

  .infoBox h3 {
    font-size: 16px; /* Reduce font size for smaller size */
  }

  .infoBox p {
    font-size: 12px; /* Reduce font size for smaller size */
    margin-bottom: -50px;
  }

  .icon {
    size: 10%;
  }

  .infoBox .icon {
    font-size: 24px;
    margin-bottom: -10px;
  }

  .container2 {
    background-image: url('https://teachyourkidscode.com/wp-content/uploads/2018/12/Teach-Your-Kids-Code-Front-Page-Image-1.jpg');
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    height: 50px; /* set the height */
    margin-bottom: 10px;
  }

  .icon {
    font-size: 50px;
  }

  .FaGraduationCap {
    font-size: 150px;
  }

  .content {
    position: relative; /* Use relative instead of absolute */
    top: 10%; /* Move 10% from the top */
    left: 50%;
    transform: translateX(-50%); /* Only translateX needed */
    width: 40%; /* Reduce the width */
    max-width: 500px; /* Reduce the maximum width */
    padding: 10px; /* Reduce padding */
    background-color: #ffffff; /* White background */
    border-radius: 10px;
    margin-top: 40px; /* Add 10px margin to the top */
}

  .content h1 {
    font-size: 20px; /* Reduce the font size of h1 */
    max-width: 450px;
  }
  
  .content p {
    font-size: 13px; /* Reduce the font size of p */
  }
  

  .infoBoxContainer2 {
    position: relative; /* Use relative instead of absolute */
    top: 50px; /* Move the container 50px down */
    left: 50%;
    transform: translateX(-50%); /* Only translateX needed */
    display: flex;
    flex-direction: row; /* Set items in a row */
    flex-wrap: wrap; /* Allow items to wrap onto new lines */
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
}

.infoBox2 {
    flex: 0 0 auto; /* Reset flex for box */
    width: calc(100% / 3 - 20px); /* Set width for the box, three per row with 20px gap */
    height: 150px; /* Set smaller height for the box */
    padding: 10px; /* Reduce padding for smaller size */
    margin: 10px; /* Add margin to separate the boxes on mobile */
    min-height: 150px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}



  .infoBox2:hover {
    transform: none; /* Remove hover effect on mobile */
  }

  .infoBox2 h3 {
    font-size: 16px; /* Reduce font size for smaller size */
  }

  .infoBox2 p {
    font-size: 12px; /* Reduce font size for smaller size */
    margin-bottom: -50px;
  }

  .icon {
    size: 10%;
  }

  .infoBox2 .icon {
    font-size: 24px;
    margin-bottom: -10px;
  }
}

