/* AdminPayment.css */

.download-invoices-section{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}

.admin-payment-container {
    margin: 0 auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th,
  td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
    text-align: left;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Conditional styling for paid payments */
  tr.paid-payment {
    background-color: #c3e6cb;
  }
  
  /* Conditional styling for unpaid payments */
  tr.unpaid-payment {
    background-color: #f5c6cb;
  }
  
  /* Hide rows based on filter selection */
  tr.hide-row {
    display: none;
  }
  
  .filter-section {
    margin-bottom: 20px;
  }
  
  .filter-section label {
    margin-right: 10px;
  }

  /* Apply media query for smaller screens */
@media (max-width: 768px) {
  table {
    font-size: 14px; /* Reduce font size for better readability on smaller screens */
  }
  
  th,
  td {
    padding: 6px; /* Further reduce the padding for better spacing on smaller screens */
  }
}

.update-payment-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f8f8;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.update-payment-section h3 {
  margin-right: 20px;
  font-weight: 500;
}

.update-payment-section .MuiTextField-root {
  flex-grow: 1;
}

  