.blinking-text {
    animation: blinkingText 1.5s infinite;
    font-size: 1.1rem;
    display: inline-block;
    color: #000;
  }
  
  @keyframes blinkingText {
    0% { color: #000; }
    50% { color: #00796b; } /* Farba, ktorá bliká */
    100% { color: #000; }
  }
  
  .new-badge {
    background-color: #ff4081;
    color: #fff;
    padding: 2px 6px;
    border-radius: 5px;
    margin-right: 8px;
    font-size: 0.9rem;
    font-weight: bold;
    animation: badgeBlink 1.5s infinite;
  }
  
  @keyframes badgeBlink {
    0% { background-color: #ff4081; }
    50% { background-color: #ff79b0; } /* Jemnejší odtieň */
    100% { background-color: #ff4081; }
  }
  