/* AdminSchool.css */

.admin-school-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .school-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .school-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .school-name {
    font-size: 18px;
    font-weight: bold;
  }
  
  .school-location {
    font-size: 14px;
  }
  
  .button-group {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .delete-button {
    padding: 8px 12px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
  }
  
  .edit-button:hover,
  .delete-button:hover {
    background-color: #45a049;
  }
  
  .new-school-form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;  /* This ensures the items are stacked vertically */
    align-items: center;     /* This centers items horizontally */
    gap: 10px;
}


.input-field {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;            
    max-width: 400px;       /* Setting a max-width can help constrain in larger screens */
    box-sizing: border-box; 
    min-width: 200px;
}

.create-button {
  display: block; /* Ensures the button is a block element */
  padding: 8px 12px;
  background-color: #2196f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: max-content;     /* The button will only be as wide as its content */
  margin: 0 auto;         /* This will center the button */
}



  
  .create-button:hover {
    background-color: #0b7dda;
  }

  .course-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f8f8f8;
  }
  
  .course-language {
    font-weight: bold;
  }
  
  .course-date {
    display: block;
    margin-top: 5px;
  }
  
  .course-lector {
    display: block;
    margin-top: 5px;
  }
  
  .edit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  /* Additional styles for the button when hovering */
  .edit-button:hover {
    background-color: #45a049;
  }
  