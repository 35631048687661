.container3 {
  display: flex;
  justify-content: space-between; 
  align-items: center;
}

.navigation-icon {
  cursor: pointer;
  margin: 10px 0; 
  font-size: 1.5rem; 
  color: gray;
  transition: color 0.3s ease-in-out;
}

.navigation-icon:hover {
  color: #A53F29;
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0.5em;
  padding: 5px; 
  width: 80px; 
  background-color: white;
  box-shadow: 0 0.5em 1em -0.125em rgba(108, 68, 46, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
  text-align: center; 
  font-size: 0.5rem; 
}

.box h2 {
  margin-bottom: 0.25em;
  font-size: 0.75rem; 
  color: #A53F29; 
}

.box p {
  margin: 0; 
  font-size: 1rem; 
}

.box-transition-group {
  position: relative;
  width: 80px; 
  margin: 0 10px; 
}

.box-transition-enter {
  opacity: 0;
}

.box-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.box-transition-exit {
  opacity: 1;
}

.box-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}

/* Styles for mobile devices */
@media (max-width: 600px) {
  .navigation-icon {
    font-size: 1rem;
    margin-left: -10px;
  }

  .box, .box-transition-group {
    width: 60%;  
    padding: 5px;
    margin: 0 1em;
  }
}
