/* global.css */

body {
    margin: 0;
    overflow-x: hidden;
  }
  
  .app-container {
    margin: 0;
    overflow-x: hidden;
    }