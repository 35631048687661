.lector-attendance-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  
  .attendance-history-table {
    width: 70%;
    margin-top: 20px;
  }
  
  .attendance-history-table h2 {
    margin-bottom: 10px;
  }
  
  /* Styles for the Create Attendance dialog */
  .MuiDialog-paper {
    width: 80%;
  }
  
  .MuiDialog-paper .MuiDialogTitle-root {
    padding: 16px;
  }
  
  .MuiDialog-paper .MuiDialogContent-root {
    padding: 16px;
  }
  
  .MuiDialog-paper .MuiDialogActions-root {
    padding: 16px;
  }
  
  .MuiDialog-paper .MuiFormControl-root {
    margin-bottom: 16px;
  }
  
  .MuiDialog-paper .MuiButton-root {
    margin-left: 8px;
  }
  
  /* Styles for the Snackbar */
  .MuiAlert-root {
    width: 100%;
    max-width: 400px;
    margin-top: 20px;
  }
  
  .MuiSnackbarContent-message {
    display: flex;
    align-items: center;
  }
  
  /* Styles for the Table */
  .MuiTable-root {
    width: 100%;
    border-collapse: collapse;
  }
  
  .MuiTableHead-root th,
  .MuiTableBody-root td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
  }
  
  .MuiTableHead-root th {
    background-color: #f0f0f0;
  }
  
  .MuiTableBody-root tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Styles for the select element in the table */
  .MuiTableBody-root select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    appearance: none;
    cursor: pointer;
  }
  
  .MuiTableBody-root select:focus {
    outline: none;
  }
  