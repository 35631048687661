.admin-lector-container {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .lector-item {
    border: 1px solid #ccc;
    margin-bottom: 10px;
    padding: 10px;
  }
  
  .lector-info {
    display: block;
    margin-bottom: 5px;
  }
  
  .lector-info-edit {
    display: block;
    margin-bottom: 5px;
    border: 1px solid #ccc;
    padding: 5px;
  }
  
  .edited {
    border-color: red;
  }
  
  button {
    margin-right: 5px;
  }
  
  /* adminLector.css */

.admin-lector-container {
  max-width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling on small screens if needed */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.lector-item {
  border-bottom: 1px solid #ddd;
}

/* Media queries for responsive layout */
@media screen and (max-width: 768px) {
  /* Hide the table headers and show data cells as block elements to create a vertical layout */
  table thead {
    display: none;
  }

  table tbody td {
    display: block;
    text-align: right; /* Align the data cells to the right */
  }

  /* Add padding and styling for each data cell */
  table tbody td {
    padding: 4px;
    border-bottom: 1px solid #ddd;
  }

  /* Show data in rows */
  table tbody tr {
    display: block;
    margin-bottom: 10px;
  }

  /* Add space between the label and value for each data cell */
  table tbody td::before {
    content: attr(data-label);
    font-weight: bold;
    display: inline-block;
    margin-right: 4px;
  }

  /* Ensure each label and value is on a separate line */
  table tbody td span {
    display: block;
  }

  /* Styling for edit and assign course buttons */
  table tbody td button {
    margin-top: 10px;
    width: 100%;
  }
}


/* style/adminLector.css */

/* ... existing styles ... */

.register-lector-form {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.register-lector-form h3 {
  margin-top: 0;
  border-bottom: 1px solid #d0d0d0;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.register-lector-form input[type="text"],
.register-lector-form input[type="email"],
.register-lector-form input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  outline: none;
  transition: border-color 0.3s;
}

.register-lector-form input[type="text"]:focus,
.register-lector-form input[type="email"]:focus,
.register-lector-form input[type="password"]:focus {
  border-color: #007bff;
}

.register-lector-form button {
  padding: 10px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.register-lector-form button:hover {
  background-color: #0056b3;
}
