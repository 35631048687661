.course-details-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .course-details {
    margin-top: 20px;
  }
  
  .course-info {
    display: block;
    margin-bottom: 10px;
  }
  
  .back-link {
    display: inline-block;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  
  .course-details-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  h2 {
    margin-bottom: 20px;
  }
  
  .course-details {
    display: flex;
    flex-direction: column;
  }
  
  .course-info {
    margin-bottom: 10px;
  }
  
  .course-info-edit {
    margin-bottom: 10px;
    padding: 5px;
    width: 100%;
  }
  
  button {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  