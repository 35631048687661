.custom-card {
    max-width: 1000px;
    margin: 40px auto;
    padding: 30px;
    background: linear-gradient(135deg, #e0e0e0 0%, #f5f5f5 100%);
    border-radius: 15px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    animation: fadeInUp 1s ease-out;
  }
  
  .custom-card:hover {
    transform: scale(1.03);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .card-title {
    text-align: center;
    font-weight: bold;
    font-size: 2.2rem;
    color: #333333;
  }
  
  .card-subtitle {
    text-align: center;
    font-size: 1.3rem;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #666666;
  }
  
  /* Smooth Animation */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Button Styles for Courses */
  .course-button {
    background-color: #4a90e2;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .course-button:hover {
    background-color: #357ab8;
    transform: translateY(-5px);
  }
  