.programming-circle-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f9f9f9;
  padding: 10px;  
  margin-bottom: 10px;  
  border-radius: 10px;
}

.info-content2, .info-content3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;  
  margin: 20px;  
}

.icon-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 10px;  
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  min-width: 20%;
  min-height: 30%;
}

.programming-circle-info h1 {
  text-align: center;
  margin-bottom: 10px;  
}

.info-text {
  flex: 1;
  padding-right: 10px;  
  margin-top: 50px;  
  margin-left: 25px;  
}

.info-img {
  flex: 1;
  height: 400px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  margin-top: 10px; 
}

.advantages {
  text-align: center;
  position: relative;
  margin-bottom: 2.5em;  
}

.icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  border-radius: 50%;
  padding: 5px;  
  color: black;
  width: 25px;
  height: 25px;
  margin-right: 5px;  
  font-size: 1rem;
  text-align: center;
}

.advantages::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: orange;
  visibility: visible;
  transform: scaleX(1);
  transition: all 0.3s ease-in-out 0s;
}

.advantages h2 {
  display: inline-block;
  position: relative; 
  padding: 0 0.5em; 
}

.icon-content {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .info-content2 .icon-content, .info-content3 .icon-content {
    flex: 0 0 100%;
  }
}

@media (min-width: 769px) {
  .info-content2 .icon-content, .info-content3 .icon-content {
    flex: 0 0 calc(25% - 10px);
  }
}

.info-img {
  height: 200px;
  margin-top: 10px;  
}
