    .nieco {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    background-color: #f4f4f4;
  }
  
  .course-details-container {
    width: 80%;
    max-width: 800px;
    margin: 20px;
    padding: 20px;
    border-radius: 15px;
    background-color: #f7f7f7;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
  
  
  .course-details-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .course-details {
    display: flex;
    flex-direction: column;
  }
  
  .course-info {
    margin-bottom: 10px;
    color: #555;
  }
  
  .course-info-edit {
    margin-left: 10px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    color: #333;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  