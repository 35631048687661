.about-us {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 40px;
  padding: 30px;
  background-color: #f2f2f2;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  max-width: 800px;
  margin: auto;
  margin-top: 50px;
}

.about-us h1 {
  color: #333;
  font-size: 2.5em;
  margin-bottom: 30px;
  text-align: center;
}

.about-us p {
  color: #666;
  line-height: 1.6;
  font-size: 1.1em;
  text-align: justify;
  margin-bottom: 20px;
}

.about-us a {
  color: #007bff;
  text-decoration: none;
}

.about-us a:hover {
  color: #0056b3;
}
