.file-download-container {
    padding: 30px;
    background-color: #f7f7f9;
    border: 1px solid #e5e7eb;
    border-radius: 12px;
    max-width: 350px;
    margin: 2rem auto;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #333;
    border-bottom: 2px solid #e5e7eb;
    padding-bottom: 10px;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 12px;
    padding: 8px 0;
    border-bottom: 1px dashed #e5e7eb;
    transition: background-color 0.3s ease;
}

li:last-child {
    border-bottom: none;
}

a {
    color: #0077cc;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease, transform 0.3s ease;
    padding: 5px 0;
    display: inline-block;
}

a:hover {
    color: #005599;
    transform: scale(1.05);
}
