/* AttendCourse.css */

.attend-course-container {
    padding: 20px;
  }
  
  .user-childrens-container {
    margin-bottom: 20px;
  }
  
  .children-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .children-table th,
  .children-table td {
    padding: 10px;
  }
  
  .children-table th:last-child,
  .children-table td:last-child {
    width: 120px; /* Fixed width for the last column */
    text-align: center; /* Center the button in the cell */
  }
  
  .details-button {
    padding: 5px 10px;
  }
  
  .attendance-icon {
    font-size: 20px;
  }

  @media only screen and (max-width: 767px) {
    .children-table {
        display: block;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
 

    .children-table, 
    .children-table thead, 
    .children-table tbody, 
    .children-table tr, 
    .children-table th, 
    .children-table td {
        display: inherit; /* Retain the default table behavior */
        width: auto;
        min-width: 124px;
        max-width: none; /* Reset max-width */
    }

    .details-button {
      background-color: red;  /* just for visibility during debugging */
      width: auto !important; 
      max-width: none !important;
      overflow: visible !important;
      white-space: normal !important;
      min-width: 120px;
  }  

    .children-table th:last-child, 
    .children-table td:last-child {
        width: auto;
    }
}
  