.dark {
    background-color: #333;
    color: #fff;
}
.light {
    background-color: #fff;
    color: #333;
}

body {
    margin: 0; /* Remove default body margin */
    overflow-x: hidden; /* Hide horizontal overflow */
  }