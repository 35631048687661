.not-logged-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  color: #333;
}

.not-logged-in h1 {
  font-size: 2.5em;
  margin-bottom: 1em;
}

.not-logged-in p {
  font-size: 1.2em;
}

.not-logged-in a {
  color: #007bff; /* Change this to the color you want */
  text-decoration: none;
}

.not-logged-in a:hover {
  color: #0056b3;
  text-decoration: underline;
}
