/* headerStyles.css */

/* Add mobile-specific styles here */
@media (max-width: 768px) {
    .content {
      top: 40%;
      width: 90%;
      padding: 20px;
    }
  
    .infoBoxContainer {
      bottom: 20px;
      display: flex;
      justify-content: center;
    }
  
    .infoBox {
      flex: 0 0 calc(33.33% - 20px);
      max-width: 100px;
      max-height: 100px;
      padding: 10px;
      margin: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .infoBox:hover {
      transform: none;
    }
  
    .infoBox h3 {
      font-size: 18px;
      margin-bottom: 5px;
    }
  
    .infoBox p {
      font-size: 12px;
    }
  
    .infoBox img {
      width: 50px;
      height: 50px;
      margin-bottom: 10px;
    }
  
    .infoBox a {
      font-size: 12px;
    }
  }
  