/* Container styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
}

/* Center the header and give some space */
h4 {
    text-align: center;
    margin: 20px 0;
}

h5 {
    margin-top: 40px;
    text-align: center;
    font-weight: bold;
}

/* Form container */
form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Grid container */
.Grid {
    width: 100%;
    max-width: 600px; /* You can adjust as per your need */
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
}

/* Table styles */
.TableContainer {
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.TableHead {
    background-color: #e0e0e0;
}

.TableCell {
    font-weight: bold;
}

/* Button styles */
.Button {
    margin-top: 20px;
    padding: 10px 20px;
}

/* Alerts */
.Snackbar {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.request-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.button-class {
   justify-content: center;
}

.Grid-item-center {
    display: flex;
    justify-content: center;
    align-items: center;
}