/* LectorAttendanceHistoryDetails.css */

.attendance-history-details-container {
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.attendance-history-details-container h2 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f2f2f2;
}

.attendance-history-details-container {
  margin-top: 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.attendance-history-details-container .top-section {
  display: flex;
  align-items: center;
}

.attendance-history-details-container p {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.attendance-history-details-container .back-link {
  text-decoration: none;
  font-weight: bold;
}

