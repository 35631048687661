.terms-and-conditions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 40px;
    padding: 30px;
    background-color: #f2f2f2;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    max-width: 800px;
    margin: auto;
    margin-top: 50px;
  }
  
  .terms-and-conditions h1 {
    color: #333;
    font-size: 2.5em;
    margin-bottom: 30px;
    text-align: center;
  }

  .terms-and-conditions h2 {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 20px;
    text-align: center;
    margin-left: 20px;
  }
  
  .terms-and-conditions p {
    color: #666;
    line-height: 1.6;
    font-size: 1.1em;
    text-align: justify;
    margin-bottom: 20px;
  }
  