.courses-container {
  max-width: 800px;
  margin: 2em auto;
  padding: 1em;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

h1 {
  text-align: center;
  margin-bottom: 1em;
  color: #333;
}

.courses-list {
  list-style-type: none;
  padding: 0;
}

.course-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 0;
  border-bottom: 1px solid #e1e1e1;
  font-size: 1.1em;
  color: #555;
}

.course-item:last-child {
  border-bottom: none;
}

.course-item button {
  background-color: #1976D2;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: background-color 0.3s;
}

.course-item button:hover {
  background-color: #1565C0;
}

.course-detail {
  margin-top: 2em;
  border-top: 1px solid #e1e1e1;
  padding-top: 1em;
}

.error-message {
  color: red;
  text-align: center;
  margin-bottom: 1em;
}

.login-note {
  margin-top: 20px;       /* Add space above the note */
  font-size: 16px;        /* Set the font size */
  color: #ff0000;         /* Make the note text color red to emphasize its importance */
  font-weight: bold;      /* Make the text bold */
  text-align: center;     /* Center the note */
  border: 1px solid #ff0000; /* Add a border around the note */
  padding: 10px;          /* Add some padding for better appearance */
  border-radius: 5px;     /* Slight rounded corners for aesthetic */
}


/* CoursesOfSchool.css */

.back-to-schools-btn {
  background-color: #f0f0f0;  /* light gray background for visibility */
  border: 1px solid #ccc;     /* gray border */
  padding: 8px 15px;    
  color: #333;      /* button padding */
  border-radius: 4px;         /* rounded edges */
  cursor: pointer;            /* clickable cursor */
  transition: background-color 0.3s;  /* smooth background-color transition */
  display: inline-block;      /* make it inline with the other elements */
  vertical-align: middle;     /* align it vertically with the other elements */
  margin-left: 10px;
  margin-bottom: 10px;
}

.back-to-schools-btn:hover {
  background-color: #e0e0e0;  /* slightly darker gray on hover */
}

@media screen and (max-width: 768px) {
  .back-to-schools-btn {
    margin-bottom: 10px;
    margin-right: 100px;          /* remove the left margin on small screens */
  }
  .courses-header {
    margin-left: -100px;
  }
}
