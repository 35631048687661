.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px; /* Adjust the margin-top value as needed */
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    margin-bottom: 20px; /* Adjust the margin-bottom value as needed */
  }
  
  .login-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px; /* Adjust the margin-bottom value as needed */
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #A53F29;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .login-button:hover {
    background-color: #8F3725;
  }
  
  .login-options {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-link {
    background: none;
    border: none;
    color: #A53F29;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 5px; /* Adjust the margin-top value as needed */
    transition: color 0.3s;
  }
  
  .login-link:hover {
    color: #8F3725;
  }


.login-popup {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff;
    color: #333333;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: opacity 0.3s;
    opacity: 1;
    z-index: 100;
    margin-top: -60px;
  }
  
  .login-popup.success {
    margin-top: -100px;
    background-color: black;
    color: #ffffff;
  }
  
  .login-popup.error {
    margin-top: -100px;
    background-color: black;
    color: #ffffff;
  }
  
  .login-popup p {
    margin: 0;
    margin-top: -15px;
    color: black;
  }
  
  .login-popup.success p {
    font-weight: bold;
    margin-top: -15px;
    color: black;
  }
  
  .login-popup.error p {
    font-weight: bold;
    margin-top: -15px;
    color: black;
  }

  .not-logged-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    margin: 0;
    padding: 0;
  }
  
  .not-logged-in-title {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .not-logged-in-text {
    font-size: 18px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .not-logged-in-button-link {
    text-decoration: none;
  }
  
  .not-logged-in-button {
    padding: 8px 16px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .not-logged-in-button:hover {
    background-color: #0056b3;
  }
  