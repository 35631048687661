.courses-work-container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    padding: 20px;
    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.15);
    border-radius: 10px;
    background-color: #fff;
}

h1 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5em;
}

.faq-container {
    margin: 0;
    padding: 0;
}

.faq-item {
    margin: 15px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
}

.faq-item:hover {
    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.15);
}

.faq-item h2 {
    cursor: pointer;
    color: #007BFF;
}

.faq-item p {
    margin-left: 1em;
    color: #333;
}
