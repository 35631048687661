.profile-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-top: 50px;
  }

  .private-policy-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 5px;
    margin-top: 50px;
  }
  
  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    margin-bottom: 20px;
    max-width: 200px;
  }
  
  .input-label {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .input-field {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .child-input {
    margin-bottom: 10px;
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  .info-label {
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .edit-button,
  .save-button {
    padding: 8px 16px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .edit-button {
    background-color: #2196f3;
  }
  
  .save-button {
    background-color: #4caf50;
  }
  
  .readonly-text {
    display: inline-block;
    padding: 8px;
    background-color: #f5f5f5;
    color: #555;
    font-weight: bold;
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .success-message {
    color: green;
    font-weight: bold;
    margin-top: 10px;
  }
  
  .invoice-details {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    color: #555;
    display: block;
    margin-bottom: 10px;
  }

  .note {
    color: red; /* or any color you prefer */
    margin-bottom: 20px; /* gives space below the note */
}
