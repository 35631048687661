.children-details-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .child-info {
    border: 1px solid #ccc;
    padding: 10px;
    margin-top: 20px;
  }
  
  .child-info span {
    display: block;
    margin-bottom: 5px;
  }
  
  .loading {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-top: 100px;
  }
  