/* StopAttending.css */

.stop-attending-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
  }
  
  .stop-attending-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .stop-attending-container input,
  .stop-attending-container textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .stop-attending-container button {
    padding: 10px 20px;
    color: white;
    background-color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }


  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 600px; /* Can adjust according to your preference */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    box-sizing: border-box;
    border-radius: 10px;
  }
  

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 20%;
  box-sizing: border-box;
}

.modal-content h2 {
  text-align: center;
  color: red;
  margin-bottom: 20px;
}

.modal h2 {
    text-align: center;
    color: yellow;
    margin-bottom: 20px;
  }

.modal-content button {
  margin: 10px 0;
  padding: 10px 20px;
  color: white;
  background-color: #333;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 20%;
}
  
  @media (max-width: 600px) {
    .stop-attending-container {
      padding: 10px;
    }
  
    .stop-attending-container h2 {
      font-size: 18px;
    }
  
    .stop-attending-container input,
    .stop-attending-container textarea {
      padding: 5px;
    }
  
    .stop-attending-container button,
    .modal-content {
        padding: 10px;
      }
    
      .modal-content h2 {
        font-size: 16px;
      }
    
      .modal-content button {
        padding: 10px;
        max-width: 20px;
      }
  }

  .cancel-message {
    text-align: center;
    color: green;
    font-size: 1.5rem;
    margin: 20px auto;
  }
  