/* LectorDetails.css */

.lector-details-container {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  margin: 10px;
}

.lector-info {
  display: grid;
  grid-template-columns: 150px 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 100px;
}

.lector-row {
  display: flex;
  align-items: center;
}

.label {
  font-weight: bold;
  margin-right: 10px;
}

/* Add more custom styles as needed */
