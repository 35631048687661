/* UserChildrens.css */

.user-childrens-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .children-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .children-table th,
  .children-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .children-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .children-table tr:hover {
    background-color: #f2f2f2;
  }

  .details-button-attendance {
    background-color: #f1c40f;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
  }

  .details-button-attendance2 {
    background-color: green;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
  }

  .details-button-attendance3 {
    background-color: red;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    margin: 2px 2px;
    cursor: pointer;
  }

 /* UserChildrens.css */

/* ... (existing styles) */

.new-school-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.form-label {
  font-weight: bold;
  flex-basis: 100px; /* Adjust the width of the label column */
  margin-left: 100px;
  position: center;
}

.form-label2{
  margin-left: -100px;
  position: center;
  margin-top: 10px;

}

.form-label3{
  margin-left: -100px;
  position: center;
  margin-top: 10px;

}

.input-field {
  flex: 1;
  padding: 4px;
  margin-left: 100px;
}

.create-button {
  padding: 8px;
  margin-left: 100px;
}

.button-container {
  display: flex;
  justify-content: center;
}

/* Media queries for mobile responsiveness */
@media (max-width: 768px) {
  .new-school-form {
    flex-direction: column;
  }

  .form-label,
  .input-field,
  .create-button {
    margin-left: 0;
  }

  .form-label {
    margin-bottom: 8px;
  }

  .create-button {
    margin-top: 16px;
  }
  .form-label3{
    margin-left: -10px;
    position: center;
    margin-top: 10px;
  }

  .form-label4{
    margin-left: -250px;
    position: center;
    margin-top: 10px;
  }
}

.admin-attendance-container {
  padding: 20px;
}

.school-select-container,
.course-select-container {
  margin-bottom: 10px;
}

.show-all-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
}

.filtered-courses-container {
  margin-top: 20px;
}

.course-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
}

.course-item h3 {
  margin-top: 0;
}

.show-details-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
}

.info-icon {
  font-size: 18px;
  color: #666;
  margin-left: 4px;
    gap: 16px;
  cursor: pointer;
}

.input-container {
  flex-direction: row;
  align-items: center;
}

.stepper-button-container {
  display: flex;
  align-items: center;
  margin-top: 16px; /* Add some margin between the buttons and the content above */
}

.stepper-button-container5 {
  display: flex;
  align-items: center;
  margin-top: 16px; /* Add some margin between the buttons and the content above */
  margin-left: -100px;
}

.stepper-button-container2 {
  align-items: center;
  margin-top: 16px; /* Add some margin between the buttons and the content above */
  margin-left: -100px;
}

.stepper-button {
  flex: 1; /* Make both buttons take equal space within the container */
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 24px; /* Increase the padding to make the buttons bigger */
  cursor: pointer;
  font-size: 16px;
  margin: 0 4px; /* Add some horizontal margin between the buttons */
}

.stepper-button-back {
  flex: 1; /* Make both buttons take equal space within the container */
  background-color: #dd00ff;
  color: #fff;
  border: none;
  padding: 12px 24px; /* Increase the padding to make the buttons bigger */
  cursor: pointer;
  font-size: 16px;
  margin: 0 4px; /* Add some horizontal margin between the buttons */
}

.first-button {
  display: flex;          /* Enable flex */
  align-items: center;    /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-left: 50%;
  text-align: center;    /* Text alignment - center */
  padding: 10px 20px;    /* Add some padding */
}


.stepper-message {
  margin-top: 16px;
  margin-bottom: 0;
}

.warning-message {
  background-color: #f44336; /* Red background */
  color: #fff; /* White text color */
  padding: 12px; /* Padding around the text */
  border-radius: 4px; /* Slightly rounded corners */
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin: 16px 0; /* Margin around the message */
  margin-left: 100px; /* Add some horizontal margin between the buttons */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow for a raised effect */
}

.add-child-container {
  margin-right: 75%;
}

@media (max-width: 768px) {
  .course-info {
    flex-direction: column;
    align-items: center;
  }
  
  .stepper-button-container {
    flex-direction: column;
  }
  
  .stepper-button, .stepper-button-back {
    width: 70%;  /* Full width */
    margin-bottom: 10px;  /* Add some vertical spacing between the buttons */
  }
  
  .first-button {
    width: 70%;  /* Full width */
    margin-bottom: 10px;  /* Add some vertical spacing between the buttons */
  }
  
  .form-label {
    text-align: center; /* Center the labels */
    position: center;
  }
  
  .warning-message {
    margin-left: 0;  /* Remove the left margin */
  }

  .first-button {
    display: flex;          /* Enable flex */
    align-items: center;    /* Center items vertically */
    justify-content: center; /* Center items horizontally */
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-align: center;    /* Text alignment - center */
    padding: 10px 20px;    /* Add some padding */
  }
}

@media only screen and (max-width: 767px) {
  .children-table {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
  }

  .children-table th {
      display: inline-block; /* make sure th is displayed on mobile */
  }

  .children-table tbody tr {
      display: block;
      margin-bottom: 0.625rem;
  }

  .children-table tbody td {
      display: inline-block; /* ensures each cell is on a new line on mobiles */
      box-sizing: border-box;
      width: 100%; /* takes the full width of its container (tr) */
      min-height: 60px; /* ensures the cell has a minimum height */
      line-height: 60px; /* centers content vertically */
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 150px; /* limits width to this value */
  }

  .details-button-attendance,
  .details-button-attendance2,
  .details-button-attendance3 {
      font-size: 10px;
      padding: 3px 6px;
      display: block;
      max-height: 50px;
      line-height: 50px;
      width: 90%; /* ensure the button takes full width */
      height: auto; /* adjust according to your design */
      line-height: normal;
      white-space: normal; /* allow button text to break into new lines if needed */
  }

  .details-button-attendance5{
    max-height: 1px;
    line-height: 1px;
  }

  .children-table tbody tr:hover {
      background-color: transparent; /* remove hover background-color */
  }

.action-cell {
  display: inline-block; /* ensures each cell is on a new line on mobiles */
  box-sizing: border-box;
  width: 120%; /* takes the full width of its container (tr) */
  min-height: 60px; /* ensures the cell has a minimum height */
  line-height: 60px; /* centers content vertically */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px; /* limits width to this value */
}
}

/* Target the horizontal line connecting each step */
.MuiStepConnector-lineHorizontal {
  display: none;
}

/* Target the vertical line connecting each step */
.MuiStepConnector-lineVertical {
  display: none;
}

.user-childrens-container {
  padding: 20px;
  background-color: #f5f5f5; /* Or any color you like */
  max-width: 100%;
  margin: 20px auto;
}

/* For the table */
.children-table {
  width: 100%;
  border-collapse: collapse;
}

/* Adjust header styles */
.children-table thead th {
  font-weight: bold;
  background-color: #eee; /* Adjust to your preference */
  padding: 8px 12px;
}

/* Adjust cell styles */
.children-table tbody td {
  padding: 8px 12px;
  border-bottom: 1px solid #ddd; /* Adds a light border between rows */
}

/* Custom button styles */
.details-button-attendance,
.details-button-attendance2,
.details-button-attendance3 {
  background-color: #1976D2; /* Example color */
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.details-button-attendance:hover,
.details-button-attendance2:hover,
.details-button-attendance3:hover {
  background-color: #1565C0; /* Darkened color for hover effect */
}

/* If you wish to have different colors for the other buttons, modify as such */
.details-button-attendance2 {
  background-color: #388E3C; /* Example color */
}

.details-button-attendance3 {
  background-color: #D32F2F; /* Example color */
}








  
  