.privacy-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; 
    padding: 50px;
    line-height: 1.8;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    max-width: 900px;
    margin: 50px auto;
}

.privacy-container h1 {
    font-size: 2.5em;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 15px;
    margin-bottom: 25px;
    color: #333;
}

.privacy-container h2 {
    font-size: 2em;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
    margin-top: 30px;
    margin-bottom: 20px;
    color: #555;
}

.privacy-container p {
    font-size: 1.2em;
    margin-bottom: 25px;
    color: #666;
}
